<template>
	<v-sheet class="recurring-main-listing" style="height: calc(87vh - 88px)">
		<v-layout class="mb-6">
			<v-row>
				<v-text-field
					right
					class="ml-3"
					ref="search"
					v-model.trim="DataSearch"
					autocomplete="off"
					hide-details
					placeholder="search."
					outlined
					@keydown.esc="onEsc"
					@keydown.enter="getSeo"
					style="max-width: 350px; min-width: 250px"
				>
					<template #prepend-inner>
						<v-icon color="grey" class="ml-1 mr-2"> mdi-magnify </v-icon>
					</template>
				</v-text-field>
				<v-btn
					depressed
					color="white"
					class="blue darken-4"
					tile
					text
					small
					style="height: 30px"
					v-on:click="getSeo"
				>
					<v-icon>mdi-magnify</v-icon>
				</v-btn>
				<v-btn
					depressed
					color="white"
					class="red darken-4 ml-2"
					tile
					text
					small
					style="height: 30px; min-width: 0px !important"
					v-on:click="resetSearch"
				>
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-row>
			<v-spacer></v-spacer>
			<v-flex class="py-0 my-auto d-flex text-right listing-right">
				<template>
					<v-flex class="d-flex justify-content-end mr-1"> </v-flex>
					<!-- <v-flex class="d-flex justify-content-end mr-1">
						<DatePicker
							hide-details
							outlined
							custom-class="mt-0"
							v-model="actula_date_filter"
							placeholder="Actual Date"
							v-on:change="actualDateFilter($event)"
						>
						</DatePicker>
					</v-flex>
 -->
					<template>
						<v-btn
							depressed
							:disabled="pageLoading"
							tile
							color="blue darken-4"
							class="text-white"
							v-on:click="importDialog = true"
						>
							<v-icon left> mdi-plus </v-icon>
							Upload Report
						</v-btn>
					</template>
				</template>
			</v-flex>
		</v-layout>

		<template>
			<div class="overflow-y" style="max-height: calc(100vh - 306px)">
				<table width="100%" class="detail-table table-head-sticky">
					<thead>
						<tr>
							<th width="10%" class="p-2 light-blue-bg custome-table">SR.No.</th>
							<th width="10%" class="p-2 light-blue-bg custome-table">Keyword</th>
							<th width="10%" class="p-2 light-blue-bg custome-table">Position on Start</th>
							<th width="10%" class="p-2 light-blue-bg custome-table">last Month Position</th>
							<th width="10%" class="p-2 light-blue-bg custome-table">Current Month Position</th>
							<th width="10%" class="p-2 light-blue-bg custome-table">Perfomance</th>
							<th width="10%" class="p-2 light-blue-bg custome-table">Traffic Range</th>
							<th width="10%" class="p-2 light-blue-bg custome-table">Actual Count</th>
							<th width="10%" class="p-2 light-blue-bg custome-table">Created At</th>
						</tr>
					</thead>
					<tbody v-if="seoReport.length">
						<tr v-for="(row, index) in seoReport" :key="index">
							<td width="10%" class="p-2 border-top-light-grey custome-table-td">
								<ShowValue :object="row" object-key="id" label="sr"></ShowValue>
							</td>
							<td width="10%" class="p-2 border-top-light-grey custome-table-td">
								<ShowValue :object="row" object-key="keyword" label="keyword"></ShowValue>
							</td>
							<td width="10%" class="p-2 border-top-light-grey custome-table-td">
								<ShowValue
									:object="row"
									object-key="position_on_start"
									label="position on start"
								></ShowValue>
							</td>
							<td width="10%" class="p-2 border-top-light-grey custome-table-td">
								<ShowValue
									:object="row"
									object-key="last_month_position"
									label="last month position"
								></ShowValue>
							</td>
							<td width="10%" class="p-2 border-top-light-grey custome-table-td">
								<ShowValue
									:object="row"
									object-key="current_month_position"
									label="current month position"
								></ShowValue>
							</td>
							<td width="10%" class="p-2 border-top-light-grey custome-table-td">
								<span v-if="row && row.performance == 0" style="color: orange">Unchanged</span>
								<span v-if="row && row.performance < 0" class="number red--text"
									>{{ Math.abs(row.performance) }}
									<v-icon size="20px" color="red">mdi-arrow-down</v-icon>
								</span>
								<span v-if="row && row.performance > 0" class="number green--text">
									{{ row.performance }}
									<v-icon size="20px" color="green">mdi-arrow-up</v-icon>
								</span>
							</td>
							<td width="10%" class="p-2 border-top-light-grey custome-table-td">
								<ShowValue :object="row" object-key="traffic_rang" label="traffic rang"></ShowValue>
							</td>
							<td width="10%" class="p-2 border-top-light-grey custome-table-td">
								<ShowValue :object="row" object-key="actual_count" label="actual count"></ShowValue>
							</td>
							<td width="10%" class="p-2 border-top-light-grey custome-table-td">
								<Chip
									class="text-capitalize"
									:text="row.added_at"
									color="green"
									text-color=""
									outlined
								></Chip>
							</td>
						</tr>
					</tbody>
					<tfoot v-else>
						<tr>
							<td colspan="7">
								<p class="m-0 row-not-found text-center py-3">
									<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
									Uhh... There <span class="text-lowercase">are no at the moment.</span>
								</p>
							</td>
						</tr>
					</tfoot>
				</table>
			</div>
		</template>
		<ImportDialog
			endpoint="seo-report/import"
			title="Seo Report"
			v-if="importDialog"
			:import-dialog.sync="importDialog"
			v-on:close="importDialog = false"
			v-on:refress="getSeo()"
			:customer-data-id="customerId"
			:project-data-id="projectId"
			:contract-data-id="contractId"
			:seo-data-id="seoId"
		></ImportDialog>
		<v-row class="my-2">
			<v-col md="6" class="my-auto">
				<p class="m-0">{{ showingString }}</p>
			</v-col>
			<v-col md="6" class="text-right">
				<v-pagination
					color="blue darken-4"
					v-model="page"
					:length="totalPage"
					total-visible="9"
					v-on:input="updatePagination($event)"
				></v-pagination>
			</v-col>
		</v-row>
	</v-sheet>
</template>
<style>
.custome-table {
	background-color: #f3f6f9;
	border-bottom: thin solid rgba(0, 0, 0, 0.12);
	border-right: 1px solid #e2e5ec !important;
}
.custome-table-td {
	border-right: 1px solid #e2e5ec !important;
}
.border-top-light-grey {
	border-top: solid 1px #e2e5ec !important;
	/*  border-bottom: solid 1px #bcc0c9 !important; */
}
</style>
<script>
/* import Dialog from "@/view/components/Dialog"; */
//import ListingMixin from "@/core/mixins/listing.mixin";
/* import { QUERY } from "@/core/services/store/request.module";
import { SET_TBODY } from "@/core/services/store/listing.module"; */
import ImportDialog from "@/view/components/ImportDialog.vue";
import ApiService from "@/core/services/api.service";
import ShowValue from "@/view/components/ShowValue";
import Chip from "@/view/components/Chip";
import { mapGetters } from "vuex";
import {
	SET_CURRENT_PAGE,
	SET_SHOWING_STRING,
	SET_TOTAL_PAGE,
} from "@/core/services/store/listing.module";

export default {
	name: "Seo-report-listing",
	title: "Listing Invoice",
	/* mixins: [ListingMixin], */
	data() {
		return {
			pageTitle: "Seo-report",
			pageBreadcrumbs: [{ text: "Seo-report", disabled: true }],
			endpoint: "seo-report",
			defaultFilter: {},
			count: {},
			status: "all",
			pageLoading: false,
			page: 1,
			DataSearch: null,
			showing_string: null,
			totalRows: 0,
			rowsOffset: 0,
			totalPages: 0,
			totalSelected: 0,
			paginationPageSize: 0,
			importDialog: false,
			seoReport: [],
		};
	},
	props: {
		customerId: {
			type: Number,
			default: 0,
		},
		projectId: {
			type: Number,
			default: 0,
		},
		contractId: {
			type: Number,
			default: 0,
		},
		seoId: {
			type: Number,
			default: 0,
		},
	},
	/* watch: {
		relatedId() {
			this.getListing();
		},
		updatedData() {
			this.getListing();
		},
	}, */
	methods: {
		getSeo(page) {
			ApiService.query(`seo-report`, { page: page, search: this.DataSearch })
				.then(({ data }) => {
					this.seoReport = data.tbody;
					this.showing_string = data.showing_string;
					this.total_page = data.total_page;
					this.$store.commit(SET_SHOWING_STRING, this.showing_string);
					this.$store.commit(SET_TOTAL_PAGE, this.total_page);
				})
				.catch((error) => {
					this.$emit("error", error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		onEsc() {
			this.resetSearch();
		},
		resetSearch() {
			const _this = this;
			_this.$nextTick(() => {
				_this.DataSearch = null;
				this.getSeo();
			});
		},
		updatePagination(param) {
			if (this.currentPage == param) {
				return false;
			}

			this.$store.commit(SET_CURRENT_PAGE, param);
			this.$nextTick(() => {
				this.getSeo(param);
			});
		},
	},
	components: {
		ImportDialog,
		ShowValue,
		Chip,
	},
	mounted() {
		this.getSeo();
		/* console.log(this.showing_string,'sjdfshgf.fhsjhdajkshdjas')
		this.$store.commit(SET_SHOWING_STRING, this.showing_string); */
	},
	computed: {
		...mapGetters(["selected", "tbody", "currentPage", "totalPage", "showingString"]),
	},
};
</script>
