<template>
	<v-sheet class="recurring-main-listing">
		<v-layout class="mb-2">
			<v-row>
				<v-text-field
					right
					class="ml-3"
					ref="search"
					v-model.trim="DataSearch"
					autocomplete="off"
					hide-details
					placeholder="search."
					outlined
					@keydown.esc="onEsc"
					@keydown.enter="getSeo"
					style="max-width: 350px; min-width: 250px"
				>
					<template #prepend-inner>
						<v-icon color="grey" class="ml-1 mr-2"> mdi-magnify </v-icon>
					</template>
				</v-text-field>
				<v-btn
					depressed
					color="white"
					class="blue darken-4"
					tile
					text
					small
					style="height: 30px"
					v-on:click="getSeo"
				>
					<v-icon>mdi-magnify</v-icon>
				</v-btn>
				<v-btn
					depressed
					color="white"
					class="red darken-4 ml-2"
					tile
					text
					small
					style="height: 30px; min-width: 0px !important"
					v-on:click="resetSearch"
				>
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-row>
			<v-spacer></v-spacer>
			<v-flex class="py-0 my-auto d-flex text-right listing-right">
				<template>
					<v-flex class="d-flex justify-content-end mr-1"> </v-flex>
					<!-- <v-flex class="d-flex justify-content-end mr-1">
						<DatePicker
							hide-details
							outlined
							custom-class="mt-0"
							v-model="actula_date_filter"
							placeholder="Actual Date"
							v-on:change="actualDateFilter($event)"
						>
						</DatePicker>
					</v-flex> -->
					<template>
						<v-btn
							depressed
							:disabled="pageLoading"
							tile
							color="blue darken-4"
							class="text-white"
							v-on:click="importDialog = true"
						>
							<v-icon left> mdi-plus </v-icon>
							Upload File
						</v-btn>
					</template>
				</template>
			</v-flex>
		</v-layout>
		<div class="overflow-y" style="max-height: calc(100vh - 306px)">
			<table width="100%" class="detail-table table-head-sticky mb-5">
				<thead>
					<tr>
						<th width="20%" class="p-2 light-blue-bg custome-table">Sr.No.</th>
						<th width="20%" class="p-2 light-blue-bg custome-table">Analysis Code</th>
						<th width="20%" class="p-2 light-blue-bg custome-table">Date</th>
						<th width="20%" class="p-2 light-blue-bg custome-table">Action</th>
					</tr>
				</thead>
				<tbody v-if="seoExcel.length">
					<tr v-for="(row, index) in seoExcel" :key="index">
						<td width="20%" class="p-2 border-top-light-grey" v-on:click="getSeo(1, row.id)">
							<ShowValue :object="row" object-key="id" label="sr"></ShowValue>
						</td>
						<td width="20%" class="p-2 border-top-light-grey" v-on:click="getSeo(1, row.id)">
							<ShowValue :object="row" object-key="name" label="name" class="text-capitalize"></ShowValue>
						</td>
						<td width="20%" class="p-2 border-top-light-grey" v-on:click="getSeo(1, row.id)">
							<span>{{ formatDateTime(row.added_at) }}</span>
						</td>
						<td width="20%" class="p-2 border-top-light-grey font-level-1">
							<v-icon
								size="20"
								class="mr-2 cursor-pointer"
								v-on:click="doAction(row, 'download')"
								color="blue darken-4"
								>mdi-download
							</v-icon>
							<v-icon size="20" v-on:click="doAction(row, 'delete')" class="cursor-pointer" color="red">
								mdi-delete
							</v-icon>
						</td>
					</tr>
				</tbody>
				<tfoot v-else>
					<tr>
						<td colspan="7">
							<p class="m-0 row-not-found text-center py-3">
								<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
								Uhh... There <span class="text-lowercase">are no file at the moment.</span>
							</p>
						</td>
					</tr>
				</tfoot>
			</table>
			<span></span>
		</div>
		<template>
			<div class="overflow-y" style="max-height: calc(100vh - 306px)">
				<table width="100%" class="detail-table table-head-sticky">
					<thead>
						<tr>
							<th width="10%" class="p-2 light-blue-bg custome-table">Sr.No.</th>
							<th width="10%" class="p-2 light-blue-bg custome-table">Keyword</th>
							<th width="10%" class="p-2 light-blue-bg custome-table">MSV Traffic</th>
							<th width="10%" class="p-2 light-blue-bg custome-table">Competition</th>
							<th width="10%" class="p-2 light-blue-bg custome-table">Current Rank</th>
							<th width="10%" class="p-2 light-blue-bg custome-table">Top 50</th>
							<th width="10%" class="p-2 light-blue-bg custome-table">Top 20</th>
							<th width="10%" class="p-2 light-blue-bg custome-table">Top 10</th>
							<th width="10%" class="p-2 light-blue-bg custome-table">Top 5</th>
							<th width="10%" class="p-2 light-blue-bg custome-table">Top 3</th>
							<th width="10%" class="p-2 light-blue-bg custome-table">Created At</th>
						</tr>
					</thead>
					<tbody v-if="seoAnalyses && seoAnalyses.length && seoAnalyses.length > 0">
						<tr v-for="(row, index) in seoAnalyses" :key="index">
							<td width="10%" class="p-2 border-top-light-grey custome-table-td">
								<ShowValue :object="row" object-key="id" label="sr"></ShowValue>
							</td>
							<td width="10%" class="p-2 border-top-light-grey custome-table-td">
								<ShowValue :object="row" object-key="keyword" label="keyword"></ShowValue>
							</td>
							<td width="10%" class="p-2 border-top-light-grey custome-table-td">
								<ShowValue :object="row" object-key="msv_trafic" label="msv_trafic"></ShowValue>
							</td>
							<td width="10%" class="p-2 border-top-light-grey custome-table-td">
								<ShowValue :object="row" object-key="competetion" label="competetion"></ShowValue>
							</td>
							<td width="10%" class="p-2 border-top-light-grey custome-table-td">
								<ShowValue :object="row" object-key="current_rank" label="current_rank"></ShowValue>
							</td>
							<td width="10%" class="p-2 border-top-light-grey custome-table-td">
								<ShowValue :object="row" object-key="top_50" label="top_50"></ShowValue>
							</td>
							<td width="10%" class="p-2 border-top-light-grey custome-table-td">
								<ShowValue :object="row" object-key="top_20" label="top_20"></ShowValue>
							</td>
							<td width="10%" class="p-2 border-top-light-grey custome-table-td">
								<ShowValue :object="row" object-key="top_10" label="top_10"></ShowValue>
							</td>
							<td width="10%" class="p-2 border-top-light-grey custome-table-td">
								<ShowValue :object="row" object-key="top_20" label="top_5"></ShowValue>
							</td>
							<td width="10%" class="p-2 border-top-light-grey custome-table-td">
								<ShowValue :object="row" object-key="top_10" label="top_3"></ShowValue>
							</td>
							<td width="10%" class="p-2 border-top-light-grey custome-table-td">
								<Chip
									class="text-capitalize"
									:text="row.added_at"
									color="green"
									text-color=""
									outlined
								></Chip>
							</td>
						</tr>
					</tbody>
					<tfoot v-else>
						<tr>
							<td colspan="11">
								<p class="m-0 row-not-found text-center py-3">
									<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
									Uhh... There <span class="text-lowercase">are no file at the moment.</span>
								</p>
							</td>
						</tr>
					</tfoot>
				</table>
			</div>
			<!-- <Table
				type="seo_analysis"
				delete-endpoint="seo_analysis/"
				v-on:reload:content="filterRows"
				v-on:update:dialog="getInvoicedata"
				delete-note="All transactions of this member will also be deleted."
			></Table> -->
		</template>
		<ImportDialog
			endpoint="seo-analyses/import"
			title="Seo Analysis"
			v-if="importDialog"
			:import-dialog.sync="importDialog"
			v-on:close="importDialog = false"
			v-on:refress="getSeoExcel()"
			:customer-data-id="customerId"
			:project-data-id="projectId"
			:contract-data-id="contractId"
			:seo-data-id="seoId"
			sampleFile="/media/sample-files/seo-analysis-sample.xlsx"
		></ImportDialog>
		<DeleteTemplate
			type="Seo-Project-Analysis"
			:deleteUrl="deleteurl"
			:deleteDialog="confirm_dialog"
			v-on:close="confirm_dialog = false"
			v-on:success="refresh"
		></DeleteTemplate>
		<v-row class="my-2" v-if="seoAnalyses && seoAnalyses.length && seoAnalyses.length > 0">
			<v-col md="6" class="my-auto">
				<p class="m-0">{{ showingString }}</p>
			</v-col>
			<v-col md="6" class="text-right">
				<v-pagination
					color="blue darken-4"
					v-model="page"
					:length="totalPage"
					total-visible="9"
					v-on:input="updatePagination($event)"
				></v-pagination>
			</v-col>
		</v-row>
	</v-sheet>
</template>
<style>
.custome-table {
	background-color: #f3f6f9;
	border-bottom: thin solid rgba(0, 0, 0, 0.12);
	border-right: 1px solid #e2e5ec !important;
}
.custome-table-td {
	border-right: 1px solid #e2e5ec !important;
}
.border-top-light-grey {
	border-top: solid 1px #e2e5ec !important;
	/*  border-bottom: solid 1px #bcc0c9 !important; */
}
</style>
<script>
/* import Dialog from "@/view/components/Dialog"; */
//import ListingMixin from "@/core/mixins/listing.mixin";
/* import { QUERY } from "@/core/services/store/request.module";
import { SET_TBODY } from "@/core/services/store/listing.module"; */
import ImportDialog from "@/view/components/ImportDialog.vue";
import ApiService from "@/core/services/api.service";
import ShowValue from "@/view/components/ShowValue";
import Chip from "@/view/components/Chip";
import { mapGetters } from "vuex";

import DeleteTemplate from "@/view/components/DeleteTemplate";

import {
	SET_CURRENT_PAGE,
	SET_SHOWING_STRING,
	SET_TOTAL_PAGE,
} from "@/core/services/store/listing.module";
export default {
	name: "Seo-Analysis-listing",
	title: "Listing Invoice",
	/* mixins: [ListingMixin], */
	data() {
		return {
			pageTitle: "Seo-Analysis",
			pageBreadcrumbs: [{ text: "Seo-Analysis", disabled: true }],
			endpoint: "seo-analysis",
			defaultFilter: {},
			count: {},
			status: "all",
			deleteurl: null,
			pageLoading: false,
			importDialog: false,
			seoAnalyses: [],
			seoExcel: [],
			DataSearch: null,
			page: 1,
			showing_string: null,
			totalRows: 0,
			rowsOffset: 0,
			confirm_dialog: false,
			totalPages: 0,
			totalSelected: 0,
			paginationPageSize: 0,
			parent_id: 0,
			id: 0,
		};
	},
	props: {
		customerId: {
			type: Number,
			default: 0,
		},
		projectId: {
			type: Number,
			default: 0,
		},
		contractId: {
			type: Number,
			default: 0,
		},
		seoId: {
			type: Number,
			default: 0,
		},
	},
	/* watch: {
		relatedId() {
			this.getListing();
		},
		updatedData() {
			this.getListing();
		},
	}, */
	methods: {
		// sampleFiles(){
		// 	return $assetURL('media/sample-files/seo-analysis-sample.xlsx');
		// },

		doAction(row, param) {
			switch (param) {
				case "download":
					window.open(row.url, "_blank");
					break;
				case "delete":
					this.deleteExcel(row.id);
					break;
			}
		},
		deleteExcel(id) {
			this.deleteurl = `seo-analysis-deleted/${id}`;
			this.confirm_dialog = true;
		},
		refresh() {
			this.getSeoExcel();
		},
		getSeo(page, parent_id = 0) {
			if (parent_id > 0) {
				this.parent_id = parent_id;
			}

			ApiService.query(`seo-analysis`, {
				page: page,
				search: this.DataSearch,
				parent_id: this.parent_id,
			})
				.then(({ data }) => {
					this.seoAnalyses = data.tbody;
					this.showing_string = data.showing_string;
					this.total_page = data.total_page;
					this.$store.commit(SET_SHOWING_STRING, this.showing_string);
					this.$store.commit(SET_TOTAL_PAGE, this.total_page);
				})
				.catch((error) => {
					this.$emit("error", error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getSeoExcel() {
			ApiService.query(`seo-analysis-excel/${this.projectId}`)
				.then(({ data }) => {
					this.seoExcel = data;
					this.parent_id = this.seoExcel[0]?.id;
				})
				.catch((error) => {
					this.$emit("error", error);
				})
				.finally(() => {
					this.pageLoading = false;
					this.getSeo();
				});
		},
		updatePagination(param) {
			if (this.currentPage == param) {
				return false;
			}

			this.$store.commit(SET_CURRENT_PAGE, param);
			this.$nextTick(() => {
				this.getSeo(param);
			});
		},
		onEsc() {
			this.resetSearch();
		},
		resetSearch() {
			const _this = this;
			_this.$nextTick(() => {
				_this.DataSearch = null;
				this.getSeo();
			});
		},
	},
	components: {
		ImportDialog,
		ShowValue,
		DeleteTemplate,
		Chip,
	},
	mounted() {
		this.getSeoExcel();
		// this.getSeo();
	},
	computed: {
		...mapGetters(["selected", "tbody", "currentPage", "totalPage", "showingString"]),
	},
};
</script>
